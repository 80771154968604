"use client";

// This file is used to initialize Mixpanel in the customer portal.
// It doesn't render anything, it just initializes Mixpanel.
import Cookie from "js-cookie";
import mixpanel from "mixpanel-browser";
import { env } from "next-runtime-env";

const disableMixpanel = env("NEXT_PUBLIC_DISABLE_MIXPANEL");

mixpanel.init(env("NEXT_PUBLIC_MIXPANEL_TOKEN") ?? "test token", {
  // avoid issue of extra large cookie preventing requests from going through:
  // https://help.mixpanel.com/hc/en-us/articles/115004546863-Mixpanel-Cookie-Too-Large
  persistence: "localStorage",
  // check for cookies from old library versions and upgrade / remove them
  upgrade: true,
  api_host: env("NEXT_PUBLIC_BACKEND_URL") + "/mp",
});
if (disableMixpanel) {
  mixpanel.opt_out_tracking();
} else {
  mixpanel.opt_in_tracking();
}

// Clear any legacy mixpanel cookies that may be left behind from using the customer portal
// across different domains, since those aren't auto-cleared by the mixpanel library
if (typeof window !== "undefined") {
  const cookies = Cookie.get() || {};
  Object.keys(cookies).forEach(cookie => {
    if (cookie.startsWith("mp_")) {
      console.log("Removing legacy mixpanel cookie", cookie);
      Cookie.remove(cookie, { path: "/", domain: ".skydio.com" });
    }
  });
}

export const MixpanelInit = () => null;
